// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import "../stylesheets/application.css";

require.context("../assets", true);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("../components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Throw a fancy message to the snoopy bois.
console.log(
  "%c The Design Release",
  "font-weight: bold; font-size: 50px;color: tomato; text-shadow: 3px 3px 0 #58CC91, 6px 6px 0 rgb(4,77,145), 9px 9px 0 rgb(2,135,206) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(245,221,8), 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)"
);
console.log(
  "%c If you want join our ranks please send code our way to jobs@thedesignrelease.com!",
  "font-weight: bold; font-size: 15px; font-style: italic;"
);
